import React, { useEffect, useState } from 'react';
import { mergeStyleSets } from '@fluentui/react';
import {
    MeasureTypes,
    Multipliers,
    refetchGetMeasureQuery,
    SeriesValue,
    useUpdateMeasureAsOfMutation,
} from '../../../data/types';
import { MeasureSeriesNames } from '../../../data/extendedTypes';
import { FrequencyTypes, getLabelByType } from '../utils/measureUtils';
import { EditPanel } from '../../../components/shared/EditPanel';
import { ValueInput } from '../../../components/inputs';
import { useStateContext } from '../../../services/contextProvider';
import dayjs from 'dayjs';

export type MeasureAsOfEditPanelMeasureAsOfType = {
    id: string | null;
    asOfDate: string;
    version: string | null;
    values:
        | {
              id: string | null;
              calcId: number | null;
              decimalValue: number | null;
              stringValue: string | null;
              dateValue: string | null;
              version: string | null;
              seriesType: {
                  name: string | null;
              } | null;
          }[]
        | null;
};

export function MeasureAsOfEditPanel(props: {
    showPanel: boolean;
    onDismiss: () => void;
    frequencyType: FrequencyTypes;
    seriesName?: MeasureSeriesNames | null;
    asOfId?: string | null;
    asOfDate?: string | null;
    fyStartDate: string;
    measure: {
        id: string | null;
        showForecast: boolean;
        measureType: MeasureTypes;
        multiplier: Multipliers;
        currency: { symbol: string | null | undefined } | null;
        decimalPlaces: number;
        valueHistory: MeasureAsOfEditPanelMeasureAsOfType[] | null;
    };
}) {
    const { currentTenantId } = useStateContext();

    let asOf: MeasureAsOfEditPanelMeasureAsOfType | null | undefined =
        props.asOfId
            ? props.measure.valueHistory?.find((ao) => ao.id === props.asOfId)
            : props.asOfDate
              ? props.measure.valueHistory?.find(
                    (ao) => ao.asOfDate === props.asOfDate
                )
              : null;

    // This is new
    if (!asOf) {
        asOf = {
            id: null,
            asOfDate: props.asOfDate || dayjs().format('YYYY-MM-DD'),
            version: '',
            values: [],
        };
    }

    const [updateMeasureAsOf, { loading: isSaving, error: saveError }] =
        useUpdateMeasureAsOfMutation();

    const [valuesInput, setValuesInput] = useState<
        {
            seriesName: MeasureSeriesNames;
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
        }[]
    >([]);

    const target = asOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Target
    );
    const actual = asOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
    );
    const forecast = asOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.PhasedForecast
    );

    const isNumberMeasureType =
        props.measure?.measureType === MeasureTypes.Numeric ||
        props.measure?.measureType === MeasureTypes.Currency ||
        props.measure?.measureType === MeasureTypes.Percentage;

    let targetYtd: number | null = null;

    if (isNumberMeasureType) {
        const previousTargets =
            asOf && props.measure.valueHistory
                ? props.measure.valueHistory
                      .filter((ao) =>
                          dayjs(ao.asOfDate).isBefore(asOf?.asOfDate)
                      )
                      .flatMap((ao) =>
                          ao.values?.find(
                              (v) =>
                                  v.seriesType?.name ===
                                  MeasureSeriesNames.Target
                          )
                      )
                : [];

        targetYtd = previousTargets.reduce(
            (sum, current) => sum + (current?.decimalValue || 0),
            0
        );

        const targetInput = valuesInput.find(
            (vi) => vi.seriesName === MeasureSeriesNames.Target
        );

        if (targetInput?.decimalValue) {
            targetYtd = targetYtd + targetInput.decimalValue;
        }
    }

    useEffect(() => {
        if (props.showPanel && asOf) {
            setValuesInput([
                {
                    seriesName: MeasureSeriesNames.Target,
                    decimalValue: target?.decimalValue ?? null,
                    stringValue: target?.stringValue ?? null,
                    dateValue: target?.dateValue ?? null,
                },
                {
                    seriesName: MeasureSeriesNames.Actual,
                    decimalValue: actual?.decimalValue ?? null,
                    stringValue: actual?.stringValue ?? null,
                    dateValue: actual?.dateValue ?? null,
                },
                {
                    seriesName: MeasureSeriesNames.PhasedForecast,
                    decimalValue: forecast?.decimalValue ?? null,
                    stringValue: forecast?.stringValue ?? null,
                    dateValue: forecast?.dateValue ?? null,
                },
            ]);
        }
    }, [target, actual, forecast, props.showPanel, asOf]);

    const updateAsOf = () => {
        if (asOf) {
            const seriesTypesToUpdate = [
                ...new Set([
                    ...valuesInput.map((t) => t.seriesName || ''),
                    ...(
                        asOf.values?.map((v) => v.seriesType?.name || '') || []
                    ).filter((st) => !!st),
                ]),
            ];

            const isNew = !asOf.id;

            updateMeasureAsOf({
                refetchQueries: () => {
                    return isNew && props.measure.id && currentTenantId
                        ? [
                              refetchGetMeasureQuery({
                                  id: props.measure.id,
                                  tenantId: currentTenantId,
                              }),
                          ]
                        : [];
                },
                variables: {
                    tenantId: currentTenantId || '',
                    input: {
                        id: asOf.id || null,
                        measureId: props.measure.id || '',
                        asOfDate: asOf.asOfDate,
                        version: asOf.version,
                        values: seriesTypesToUpdate.map((st) => {
                            const original = asOf?.values?.find(
                                (v) => v.seriesType?.name === st
                            );

                            const value: SeriesValue = {
                                id: original ? original.id : null,
                                calcId: original?.calcId || null,
                                version: original?.version || '',
                                decimalValue: original?.decimalValue || null,
                                dateValue: original?.dateValue || null,
                                stringValue: original?.stringValue || null,
                                seriesType: {
                                    id: null,
                                    name: st,
                                    calcSymbol: null,
                                    defaultFormat: '',
                                    sequence: null,
                                },
                            };

                            const updatedValue = valuesInput.find(
                                (input) => input.seriesName === st
                            );

                            if (updatedValue) {
                                value.decimalValue = updatedValue.decimalValue;
                                value.stringValue = updatedValue.stringValue;
                                value.dateValue = updatedValue.dateValue;
                            }

                            return value;
                        }),
                    },
                },
            });
            props.onDismiss();
        }
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 8,
            gap: 16,
        },
    });

    const asOfLabel = getLabelByType(
        props.fyStartDate,
        props.frequencyType,
        asOf?.asOfDate
    );

    return (
        <EditPanel
            showPanel={props.showPanel}
            onDismiss={props.onDismiss}
            headerText={
                props.seriesName
                    ? `Update Value (${asOfLabel})`
                    : `Update Values (${asOfLabel})`
            }
            saveErrorMessage={saveError?.message}
            onUpdateClick={updateAsOf}
            isSaving={isSaving}
            isValid={true}
            activeViewName={null}
        >
            <div className={classNames.container}>
                {(props.seriesName === MeasureSeriesNames.Target ||
                    !props.seriesName) && (
                    <ValueInput
                        label="Target"
                        measureType={props.measure.measureType}
                        multiplier={props.measure.multiplier}
                        currencySymbol={props.measure.currency?.symbol}
                        decimalPlaces={props.measure.decimalPlaces}
                        defaultValue={target}
                        onChange={function (
                            decimalValue: number | null,
                            stringValue: string | null,
                            dateValue: string | null
                        ): void {
                            setValuesInput([
                                ...valuesInput.filter(
                                    (v) =>
                                        v.seriesName !==
                                        MeasureSeriesNames.Target
                                ),
                                {
                                    seriesName: MeasureSeriesNames.Target,
                                    decimalValue,
                                    stringValue,
                                    dateValue,
                                },
                            ]);
                        }}
                    />
                )}

                {(props.seriesName === MeasureSeriesNames.Target ||
                    !props.seriesName) &&
                    isNumberMeasureType &&
                    targetYtd !== null && (
                        <ValueInput
                            label="Target (YTD)"
                            measureType={props.measure.measureType}
                            multiplier={props.measure.multiplier}
                            currencySymbol={props.measure.currency?.symbol}
                            decimalPlaces={props.measure.decimalPlaces}
                            disabled
                            defaultValue={{
                                decimalValue: targetYtd,
                            }}
                            onChange={function (): void {
                                // TODO
                            }}
                        />
                    )}

                {(props.seriesName === MeasureSeriesNames.Actual ||
                    !props.seriesName) && (
                    <ValueInput
                        label="Actual"
                        measureType={props.measure.measureType}
                        multiplier={props.measure.multiplier}
                        currencySymbol={props.measure.currency?.symbol}
                        decimalPlaces={props.measure.decimalPlaces}
                        defaultValue={actual}
                        onChange={function (
                            decimalValue: number | null,
                            stringValue: string | null,
                            dateValue: string | null
                        ): void {
                            setValuesInput([
                                ...valuesInput.filter(
                                    (v) =>
                                        v.seriesName !==
                                        MeasureSeriesNames.Actual
                                ),
                                {
                                    seriesName: MeasureSeriesNames.Actual,
                                    decimalValue,
                                    stringValue,
                                    dateValue,
                                },
                            ]);
                        }}
                    />
                )}

                {props.measure.showForecast &&
                    (props.seriesName === MeasureSeriesNames.PhasedForecast ||
                        !props.seriesName) && (
                        <ValueInput
                            label="Forecast"
                            measureType={props.measure.measureType}
                            multiplier={props.measure.multiplier}
                            currencySymbol={props.measure.currency?.symbol}
                            decimalPlaces={props.measure.decimalPlaces}
                            defaultValue={forecast}
                            onChange={function (
                                decimalValue: number | null,
                                stringValue: string | null,
                                dateValue: string | null
                            ): void {
                                setValuesInput([
                                    ...valuesInput.filter(
                                        (v) =>
                                            v.seriesName !==
                                            MeasureSeriesNames.PhasedForecast
                                    ),
                                    {
                                        seriesName:
                                            MeasureSeriesNames.PhasedForecast,
                                        decimalValue,
                                        stringValue,
                                        dateValue,
                                    },
                                ]);
                            }}
                        />
                    )}
            </div>
        </EditPanel>
    );
}
