import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import {
    useGetMeasuresForStatusReportQuery,
    useGetStatusReportQuery,
    useGetTasksForStatusReportQuery,
    GetMeasuresForStatusReportQuery,
    GetTasksForStatusReportQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { StepContainer } from './components/StepContainer';
import { Markdown } from './Markdown';
import { sorters } from '../../data/sorters';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../../data/extendedTypes';
import { MeasureArrowIcon } from '../../components/MeasureArrowIcon';
import { useFormatters } from '../../hooks/useFormatters';
import { PresentationTitleSlide } from './components/PresentationTitleSlide';
import { PresentationSlide } from './components/PresentationSlide';
import { PresentationBlockquote } from './components/PresentationBlockquote';
import { MissionPresentationSlide } from './components/MissionPresentationSlide';
import { useReportingMeasures } from '../../hooks/useReportingMeasures';
import { PresentationMeasureBox } from './components/PresentationMeasureBox';

export function StatusReportPresentation(): JSX.Element {
    const handleMessage = () => {
        parent.document.dispatchEvent(new Event('presentation:action'));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleMessage, false);
        window.addEventListener('mousedown', handleMessage, false);
        window.addEventListener('touchstart', handleMessage, false);
    }, []);

    const [revealApi, setRevealApi] = useState<Reveal.Api>();
    const handleInitialized = setRevealApi;
    const handleSectionChanged = () => {
        if (revealApi?.sync) {
            revealApi.sync();
        }
    };

    const { currentTenantId } = useStateContext();

    const { missionId, statusReportId } = useParams();

    const { data } = useGetStatusReportQuery({
        skip: !statusReportId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            statusReportId: statusReportId || '',
        },
        pollInterval: 10000,
    });

    const forDateTime =
        data?.statusReport?.utcDataDate ||
        data?.statusReport?.utcCompletedDate ||
        null;

    const { data: measureData } = useGetMeasuresForStatusReportQuery({
        skip: !data?.statusReport,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
            forDateTime: forDateTime,
            includeDeleted: true,
        },
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const { data: taskData } = useGetTasksForStatusReportQuery({
        skip: !data?.statusReport,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
            forDateTime: forDateTime,
            includeDeleted: true,
        },
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const {
        measures: periodMeasures,
        setSelectedMeasureIds,
        periodData,
    } = useReportingMeasures({
        missionId: missionId || null,
        reportPeriod: null,
        reportPeriodType: null,
        //reportPeriod: data?.statusReport?.reportPeriod || null,
        //reportPeriodType: data?.statusReport?.reportPeriodType || null,
        utcCompletedDate: data?.statusReport?.utcCompletedDate || null,
        utcDataDate: data?.statusReport?.utcDataDate || null,
    });

    const statusReport = data?.statusReport;
    const measures = measureData?.measures;
    const tasks = taskData?.tasks;
    const mission = statusReport?.mission;

    useEffect(() => {
        if (statusReport?.facts) {
            setSelectedMeasureIds(
                statusReport?.facts
                    .filter((f) => f.isIncluded)
                    .filter((f) => !!f.measureId)
                    .map((t) => t.measureId || '')
            );
        } else {
            setSelectedMeasureIds([]);
        }
    }, [statusReport?.facts, setSelectedMeasureIds]);

    const includedFacts = statusReport?.facts
        ?.filter((f) => f.isIncluded)
        .sort(sorters.sequenceSorter);

    const isReady = !!mission && !!statusReport && !!measures && !!tasks;
    const steps: JSX.Element[] = [];

    const formattedReportDate = statusReport?.reportDate
        ? dayjs.utc(statusReport?.reportDate).format('DD MMM YYYY')
        : null;

    steps.push(
        <PresentationTitleSlide
            key="title"
            title={`${statusReport?.title || 'Status Report'}${formattedReportDate ? ` (${formattedReportDate})` : ''}${!data?.statusReport?.utcCompletedDate ? ' - Draft' : ''}`}
            mission={mission}
            onSectionChanged={handleSectionChanged}
        />
    );

    if (statusReport?.summaryText?.trim()) {
        steps.push(
            <PresentationSlide
                key="executive-summary"
                sectionKey="executive-summary"
                leftSize="none"
            >
                <PresentationSlide.ContentScrollContainer>
                    <div className="mintListHeader">
                        <h6>Executive Summary</h6>
                    </div>
                    <div style={{ paddingRight: 16 }} className="mintListBody">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'stretch',
                                minWidth: 0,
                                minHeight: 'auto',
                                gap: 'min(0.8vw, 16px)',
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: 2,
                                    flexBasis: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 'min(0.8vw, 16px)',
                                }}
                            >
                                <h6 style={{ marginBottom: 0 }}>&nbsp;</h6>
                                <div className="mintMarkdownFrame">
                                    <Markdown
                                        source={statusReport.summaryText?.trim()}
                                        className="mintMarkdownBoxNoScroll"
                                    />
                                </div>
                            </div>

                            {!!periodMeasures.length && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        flexBasis: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 'min(0.8vw, 16px)',
                                    }}
                                >
                                    <h6 style={{ marginBottom: 0 }}>
                                        Key Measures of Success
                                    </h6>

                                    {statusReport?.facts
                                        ?.slice()
                                        .filter((r) => r.isIncluded)
                                        .filter((r) => r.measureId)
                                        .sort(sorters.sequenceSorter)
                                        .map((ml) => {
                                            const measure = measures?.find(
                                                (m) => m.id === ml.measureId
                                            );

                                            if (!measure) {
                                                return null;
                                            }

                                            const measurePeriodData =
                                                periodData.find(
                                                    (pd) =>
                                                        pd.measureId ===
                                                            measure?.id ||
                                                        (measure?.isLinked &&
                                                            measure.linkedFromMeasureId ===
                                                                pd.measureId)
                                                );

                                            if (!measurePeriodData) {
                                                return null;
                                            }

                                            return (
                                                <div key={ml.id}>
                                                    <PresentationMeasureBox
                                                        measure={measure}
                                                        periodData={
                                                            measurePeriodData
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    steps.push(
        <MissionPresentationSlide
            key="mission"
            mission={mission}
            measures={measures}
        />
    );

    if (statusReport?.lastPeriodText?.trim()) {
        steps.push(
            <PresentationSlide
                key="last-period"
                sectionKey="last-period"
                leftSize="none"
            >
                <PresentationSlide.ContentContainer>
                    <div className="mintListHeader">
                        <h6>Last 30 days</h6>
                    </div>
                    <div style={{ paddingRight: 16 }} className="mintListBody">
                        <div className="mintMarkdownFrame">
                            <Markdown
                                source={statusReport.lastPeriodText?.trim()}
                                className="mintMarkdownBoxNoScroll"
                            />
                        </div>
                    </div>
                </PresentationSlide.ContentContainer>
            </PresentationSlide>
        );
    }

    includedFacts?.forEach((f) => {
        const measure = f.measureId
            ? measures?.find((m) => m.id === f.measureId)
            : null;

        const task = f.taskId ? tasks?.find((t) => t.id === f.taskId) : null;

        const sectionCount =
            (f.soWhatText ? 1 : 0) +
            (f.insightText ? 1 : 0) +
            (f.actionText ? 1 : 0);

        const sectionHeight = sectionCount
            ? `${60 / sectionCount}vh`
            : undefined;

        steps.push(
            <PresentationSlide
                key={f.id}
                sectionKey={`fact_${f.id}`}
                leftSize="small"
            >
                <PresentationSlide.LeftSection></PresentationSlide.LeftSection>
                <PresentationSlide.RightSection fullHeight>
                    <div className="mintListHeader">
                        {!task ||
                            (task.utcDeleted !== null && (
                                <h5 style={{ fontStyle: 'italic' }}>
                                    This task has been deleted
                                </h5>
                            ))}
                        {task && (
                            <h5
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    textDecoration: task?.utcDeleted
                                        ? 'line-through'
                                        : undefined,
                                }}
                            >
                                {task?.name}
                            </h5>
                        )}
                        {!measure ||
                            (measure.utcDeleted !== null && (
                                <h5 style={{ fontStyle: 'italic' }}>
                                    This measure of success has been deleted
                                </h5>
                            ))}
                        {measure && (
                            <h5
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    textDecoration: measure?.utcDeleted
                                        ? 'line-through'
                                        : undefined,
                                }}
                            >
                                {measure?.name}
                            </h5>
                        )}
                        {!!f.factText && (
                            <h5 style={{ whiteSpace: 'pre-wrap' }}>
                                {f.factText}
                            </h5>
                        )}
                    </div>

                    <div style={{ paddingRight: 16 }}>
                        {measure && (
                            <StatusReportPresentationMeasureBar
                                measure={measure}
                            />
                        )}
                        {task && (
                            <StatusReportPresentationTaskBar task={task} />
                        )}
                    </div>

                    {!!f.soWhatText && (
                        <div style={{ paddingRight: 16 }}>
                            <PresentationBlockquote label="So What">
                                <Markdown
                                    source={f?.soWhatText}
                                    className="mintMarkdownBox"
                                    style={{
                                        maxHeight: sectionHeight,
                                    }}
                                />
                            </PresentationBlockquote>
                        </div>
                    )}
                    {!!f.insightText && (
                        <div style={{ paddingRight: 16 }}>
                            <PresentationBlockquote label="Insight">
                                <Markdown
                                    source={f?.insightText}
                                    className="mintMarkdownBox"
                                    style={{
                                        maxHeight: sectionHeight,
                                    }}
                                />
                            </PresentationBlockquote>
                        </div>
                    )}
                    {!!f.actionText && (
                        <div style={{ paddingRight: 16 }}>
                            <PresentationBlockquote label="Action">
                                <Markdown
                                    source={f?.actionText}
                                    className="mintMarkdownBox"
                                    style={{
                                        maxHeight: sectionHeight,
                                    }}
                                />
                            </PresentationBlockquote>
                        </div>
                    )}

                    {!!f.actions.length && (
                        <div style={{ paddingRight: 16 }}>
                            <PresentationBlockquote label="Action">
                                <div className="mintMarkdownBox">
                                    <ul>
                                        {f.actions.map((a) => (
                                            <li key={a.id}>
                                                <div>{a.name}</div>
                                                <div
                                                    style={{ fontSize: '1vw' }}
                                                >
                                                    {a.due
                                                        ? `Due: ${dayjs.utc(a.due).format('DD MMM YYYY')}`
                                                        : ''}
                                                </div>
                                                <div
                                                    style={{ fontSize: '1vw' }}
                                                >
                                                    {a.done
                                                        ? `Done: ${dayjs.utc(a.done).format('DD MMM YYYY')}`
                                                        : ''}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </PresentationBlockquote>
                        </div>
                    )}
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    });

    if (statusReport?.nextPeriodText?.trim()) {
        steps.push(
            <PresentationSlide
                key="next-period"
                sectionKey="next-period"
                leftSize="small"
            >
                <PresentationSlide.LeftSection />
                <PresentationSlide.RightSection>
                    <div style={{ paddingRight: 16 }}>
                        <PresentationBlockquote
                            label="Next 30 days"
                            align="start"
                        >
                            <Markdown
                                source={statusReport?.nextPeriodText}
                                className="mintMarkdownBox"
                            />
                        </PresentationBlockquote>
                    </div>
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    }

    if (
        statusReport?.risksAndOpportunitiesText?.trim() ||
        statusReport?.supportText?.trim()
    ) {
        const boxCount =
            (statusReport?.risksAndOpportunitiesText?.trim() ? 1 : 0) +
            (statusReport?.supportText?.trim() ? 1 : 0);

        steps.push(
            <PresentationSlide
                key="risks-opportunities-support"
                sectionKey="risks-opportunities-support"
                leftSize="small"
            >
                <PresentationSlide.LeftSection />
                <PresentationSlide.RightSection>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 16,
                            height: '100%',
                        }}
                    >
                        {!!statusReport?.risksAndOpportunitiesText?.trim() && (
                            <div
                                style={{
                                    paddingRight: 16,
                                }}
                            >
                                <PresentationBlockquote
                                    label="Risks / Opportunities"
                                    align="start"
                                >
                                    <Markdown
                                        source={
                                            statusReport?.risksAndOpportunitiesText
                                        }
                                        className="mintMarkdownBox"
                                        style={{
                                            maxHeight: `${80 / boxCount}vh`,
                                        }}
                                    />
                                </PresentationBlockquote>
                            </div>
                        )}
                        {!!statusReport?.supportText?.trim() && (
                            <div
                                style={{
                                    paddingRight: 16,
                                }}
                            >
                                <PresentationBlockquote
                                    label="Support"
                                    align="start"
                                >
                                    <Markdown
                                        source={statusReport?.supportText}
                                        className="mintMarkdownBox"
                                        style={{
                                            maxHeight: `${80 / boxCount}vh`,
                                        }}
                                    />
                                </PresentationBlockquote>
                            </div>
                        )}
                    </div>
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    }

    return (
        <StepContainer
            isReady={isReady}
            steps={steps}
            onInitialized={handleInitialized}
        />
    );
}

type MeasureType = ExtractQueryArrayType<
    GetMeasuresForStatusReportQuery,
    ['measures']
>;

type TaskType = ExtractQueryArrayType<GetTasksForStatusReportQuery, ['tasks']>;

function StatusReportPresentationMeasureBar(props: {
    measure: MeasureType;
}): JSX.Element {
    const { measure } = props;

    const lastAsOf = measure.lastAsOf;

    const formatters = useFormatters();

    const target = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Target
    );

    const actual = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
    );

    const formattedPercentage =
        target !== undefined
            ? formatters.formatMeasureStatus(
                  lastAsOf?.statusValue,
                  measure.isStatusLimited
              )
            : '';

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'end',
                paddingRight: 16,
            }}
        >
            {target?.formatStr !== undefined && (
                <h6>Target: {target?.formatStr}</h6>
            )}
            {actual?.formatStr !== undefined && (
                <h6>Actual: {actual?.formatStr}</h6>
            )}

            {formattedPercentage !== '' && (
                <h6>Status: {formattedPercentage}</h6>
            )}

            {!!lastAsOf?.arrowColour &&
                !!lastAsOf?.arrowDirection &&
                target !== undefined && (
                    <MeasureArrowIcon
                        arrowColour={
                            target !== undefined
                                ? lastAsOf?.arrowColour
                                : 'NONE'
                        }
                        arrowDirection={
                            target !== undefined
                                ? lastAsOf?.arrowDirection
                                : 'NONE'
                        }
                        iconFontSize={24}
                    />
                )}
        </div>
    );
}

function StatusReportPresentationTaskBar(props: {
    task: TaskType;
}): JSX.Element {
    const { task } = props;

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const formattedPercentage = formatter.format(task?.percentComplete ?? 0);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'end',
                paddingRight: 16,
            }}
        >
            {task.taskCategory && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 4,
                    }}
                >
                    <span
                        style={{
                            color: `#${task.taskCategory?.colourHex ?? 'fff'}`,
                            fontSize: '2vw',
                        }}
                    >
                        &#x25A0;
                    </span>
                    <span>
                        <h6>{task.taskCategory?.name}</h6>
                    </span>
                </div>
            )}

            <h6>Percentage Complete: {formattedPercentage}</h6>
        </div>
    );
}
