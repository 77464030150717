import React, { useCallback, useMemo } from 'react';
import { useNavigate, generatePath } from 'react-router';
import { IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react';
import {
    useGetBreadcrumbMissionForFyLazyQuery,
    useGetBreadcrumbsQuery,
    useGetFinancialYearsQuery,
} from '../../data/types';
import { navigation, paths } from '../../services/navigation';
import { useDispatchContext } from '../../services/contextProvider';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { AdvanceBreadcrumb } from './Breadcrumbs';

export function useBreadcrumbItems(location: {
    tenantId: string;
    financialYearCode: string;
    tenantCode: string;
    tenantName: string;
    teamCode: string | undefined;
    missionId: string | undefined;
    contributorId: string | undefined;
}): { items: AdvanceBreadcrumb[]; loading: boolean } {
    const {
        tenantId,
        financialYearCode,
        tenantCode,
        tenantName,
        teamCode,
        missionId,
        contributorId,
    } = location;

    const navigate = useNavigate();
    const dispatch = useDispatchContext();

    const updateContext = useCallback(
        (context: {
            tenantCode: string;
            teamCode: string | null | undefined;
            themePrimaryColourHex: string | null | undefined;
            missionId?: string | null | undefined;
        }): void => {
            dispatch({
                type: 'SetCurrentTeamAndMission',
                payload: {
                    currentTenantCode: context.tenantCode,
                    currentTeamCode: context.teamCode,
                    currentMissionId: context.missionId,
                    themePrimaryColourHex: context.themePrimaryColourHex,
                },
            });
        },
        [dispatch]
    );

    const { loading, data } = useGetBreadcrumbsQuery({
        skip: !teamCode || !tenantId || !financialYearCode,
        variables: {
            tenantId: tenantId,
            financialYearCode: financialYearCode,
            teamCode: teamCode?.toLowerCase() || '',
        },
    });

    const { loading: fyLoading, data: fyData } = useGetFinancialYearsQuery({
        skip: !tenantId,
        variables: {
            tenantId: tenantId,
        },
    });

    const [GetBreadcrumbMissionForFy] = useGetBreadcrumbMissionForFyLazyQuery();

    const sortedFys = useMemo(
        () =>
            orderBy(
                filter(fyData?.financialYears, (fy) => !fy.utcInactive),
                ['startDate', 'code']
            ),
        [fyData?.financialYears]
    );

    const currentMissionItem: AdvanceBreadcrumb | null = useMemo(() => {
        if (!missionId || loading) {
            return null;
        }

        let currentMission:
            | { owner: string | null; id: string | null }
            | null
            | undefined = data?.breadcrumbs
            ?.flatMap((b) => b.missions)
            .find((t) => t?.id === missionId);

        if (!currentMission) {
            currentMission = data?.breadcrumbs
                ?.flatMap((b) => b.leaderMission)
                .find((lm) => lm?.id === missionId);
        }

        if (!currentMission) {
            currentMission = data?.breadcrumbs
                ?.flatMap((b) => b.dottedMissions)
                .find((dm) => dm?.mission?.id === missionId)?.mission;
        }

        return currentMission
            ? {
                  key: `currentMission${currentMission.id}`,
                  text: currentMission.owner || '',
                  isCurrentItem: true,
              }
            : null;
    }, [missionId, data?.breadcrumbs, loading]);

    const currentContributorItem: AdvanceBreadcrumb | null = useMemo(() => {
        if (!contributorId || loading) {
            return null;
        }

        const currentContributor = data?.breadcrumbs
            ?.flatMap((b) => b.contributors)
            .find((c) => c?.id === contributorId);

        return currentContributor
            ? {
                  key: `contributor${currentContributor.id}`,
                  text: currentContributor.userDisplayName || '',
                  isCurrentItem: true,
              }
            : null;
    }, [contributorId, data?.breadcrumbs, loading]);

    const getContributorMenuItem = useCallback(
        (
            contributor: {
                id: string | null;
                userDisplayName: string | null;
            },
            teamCode: string | null | undefined,
            primaryColourHex: string | null | undefined
        ): IContextualMenuItem => {
            return {
                key: 'subMenu_con' + contributor.id,
                text: contributor.userDisplayName || contributor.id || '',
                secondaryText: 'Contributor',
                iconProps: {
                    iconName: 'Contact',
                },
                href: generatePath(paths.contributorWithTeam, {
                    tenantCode: tenantCode?.toLowerCase(),
                    financialYearCode: financialYearCode?.toLowerCase(),
                    teamCode: teamCode?.toLowerCase() || '',
                    contributorId: contributor.id,
                }),
                onClick: (
                    ev?:
                        | React.MouseEvent<HTMLElement>
                        | React.KeyboardEvent<HTMLElement>,
                    item?: IContextualMenuItem
                ): boolean => {
                    if (item && item.href) {
                        updateContext({
                            tenantCode: tenantCode,
                            teamCode: teamCode?.toLowerCase(),
                            themePrimaryColourHex: primaryColourHex,
                            missionId: null,
                        });
                        navigate(item.href);
                    }
                    // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                    if (ev) {
                        ev.preventDefault();
                        ev.defaultPrevented = false;
                    }
                    return true;
                },
            };
        },
        [tenantCode, financialYearCode, navigate, updateContext]
    );

    const navigateToFy = useCallback(
        async (fyCode: string | null) => {
            const currentMission = missionId
                ? data?.breadcrumbs
                      ?.flatMap((b) => b.missions)
                      .find((m) => m.id === missionId) ||
                  data?.breadcrumbs?.find(
                      (b) => b.leaderMission?.id === missionId
                  )?.leaderMission ||
                  data?.breadcrumbs
                      ?.flatMap((b) => b.dottedMissions)
                      .find((dm) => dm.mission?.id === missionId)?.mission
                : null;

            let targetTeamCode: string | undefined = undefined;
            let targetMissionId: string | undefined = undefined;

            if (currentMission?.userId && fyCode) {
                const fyMission = await GetBreadcrumbMissionForFy({
                    variables: {
                        tenantId: tenantId,
                        hintUserId: currentMission?.userId,
                        financialYearCode: fyCode,
                    },
                });

                if (
                    fyMission.data?.default_mission?.id &&
                    fyMission.data?.default_mission.team?.code
                ) {
                    targetTeamCode =
                        fyMission.data?.default_mission.team?.code || undefined;
                    targetMissionId =
                        fyMission.data?.default_mission.id || undefined;
                }
            }

            dispatch({
                type: 'SetupContext',
                payload: {
                    currentTenantCode: tenantCode,
                    currentFinancialYearCode: fyCode,
                    themePrimaryColourHex: undefined,
                    currentTeamCode: targetTeamCode?.toLowerCase(),
                    currentMissionId: targetMissionId,
                },
            });

            const href = navigation.getPathForParams({
                tenantCode: tenantCode,
                financialYearCode: fyCode || undefined,
                teamCode: targetTeamCode?.toLowerCase(),
                missionId: targetMissionId,
            });

            navigate(href);
        },
        [
            GetBreadcrumbMissionForFy,
            data?.breadcrumbs,
            dispatch,
            missionId,
            navigate,
            tenantCode,
            tenantId,
        ]
    );

    const breadCrumbItems: AdvanceBreadcrumb[] | null = useMemo(() => {
        if (loading || fyLoading) {
            return [];
        }

        const items: AdvanceBreadcrumb[] = (data?.breadcrumbs || []).map(
            (breadcrumb) => {
                if (!breadcrumb) {
                    throw new Error('NULL breadcrumb found.');
                }

                let teamMissions: {
                    id: string | null;
                    sequence: number;
                    title: string | null;
                    owner: string | null;
                    ownTeam: {
                        code: string | null;
                        name: string | null;
                        division: {
                            id: string | null;
                            primaryColourHex: string | null;
                        } | null;
                        dottedMissions: {
                            id: string | null;
                            sequence: number;
                            mission: {
                                id: string | null;
                                title: string | null;
                                owner: string | null;
                            } | null;
                        }[];
                        missions:
                            | {
                                  id: string | null;
                                  title: string | null;
                                  owner: string | null;
                                  sequence: number;
                              }[]
                            | null;
                        contributors: {
                            id: string | null;
                            userDisplayName: string | null;
                            userActive: boolean;
                        }[];
                    } | null;
                }[] = [];

                breadcrumb?.missions.forEach((m) => {
                    const ownTeam =
                        m.leaderOfTeams && m.leaderOfTeams.length > 0
                            ? m.leaderOfTeams[0]
                            : null;

                    teamMissions.push({
                        ...m,
                        ownTeam: ownTeam,
                    });
                });

                breadcrumb?.dottedMissions.forEach((dm) => {
                    if (dm.mission) {
                        teamMissions.push({
                            ...dm.mission,
                            sequence: dm.sequence,
                            ownTeam: null,
                        });
                    }
                });

                // filter out the leader mission here as it will be added later.
                teamMissions = teamMissions.filter(
                    (mission) =>
                        mission &&
                        (!breadcrumb.leaderMission ||
                            mission.id !== breadcrumb.leaderMission.id)
                );

                teamMissions = orderBy(teamMissions, 'sequence');

                const menuItems: IContextualMenuItem[] = teamMissions.map(
                    (mission) => {
                        if (!mission) {
                            throw new Error('NULL mission found.');
                        }

                        const currentTeamCode = mission.ownTeam
                            ? mission.ownTeam.code
                            : breadcrumb.code
                              ? breadcrumb.code
                              : teamCode;

                        const division = mission.ownTeam
                            ? mission.ownTeam.division
                            : breadcrumb.division;

                        const subMissions =
                            mission.ownTeam?.missions
                                ?.map((m) => {
                                    return {
                                        id: m.id,
                                        sequence: m.sequence,
                                        title: m.title,
                                        owner: m.owner,
                                    };
                                })
                                .concat(
                                    mission.ownTeam.dottedMissions.map((dm) => {
                                        return {
                                            sequence: dm.sequence,
                                            id: dm.mission?.id || null,
                                            title: dm.mission?.title || null,
                                            owner: dm.mission?.owner || null,
                                        };
                                    })
                                ) || [];

                        const subMenuItems: IContextualMenuItem[] = orderBy(
                            subMissions,
                            'sequence'
                        ).map((m) => {
                            return (m && {
                                key: `subMenu${m.id}`,
                                text: m.owner,
                                secondaryText: m.title,
                                iconProps: {
                                    iconName: 'Contact',
                                },
                                href: generatePath(paths.missionBuilder, {
                                    tenantCode: tenantCode?.toLowerCase(),
                                    financialYearCode:
                                        financialYearCode?.toLowerCase(),
                                    teamCode:
                                        mission.ownTeam?.code?.toLowerCase() ||
                                        '',
                                    missionId: m.id || '',
                                }),
                                onClick: (
                                    ev?:
                                        | React.MouseEvent<HTMLElement>
                                        | React.KeyboardEvent<HTMLElement>,
                                    item?: IContextualMenuItem
                                ): boolean => {
                                    if (item && item.href) {
                                        updateContext({
                                            tenantCode: tenantCode,

                                            teamCode:
                                                mission.ownTeam?.code?.toLowerCase(),
                                            themePrimaryColourHex:
                                                mission.ownTeam?.division
                                                    ?.primaryColourHex,
                                            missionId: m.id,
                                        });
                                        navigate(item.href);
                                    }
                                    // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                    if (ev) {
                                        ev.preventDefault();
                                        ev.defaultPrevented = false;
                                    }
                                    return true;
                                },
                            }) as IContextualMenuItem;
                        });

                        if (mission.ownTeam) {
                            // Add the primary mission
                            subMenuItems.unshift({
                                key: 'primary' + mission.id,
                                text: mission.owner,
                                secondaryText: mission.title,
                                iconProps: {
                                    iconName: 'PartyLeader',
                                },
                                href: generatePath(paths.missionBuilder, {
                                    tenantCode: tenantCode?.toLowerCase(),
                                    financialYearCode:
                                        financialYearCode?.toLowerCase(),
                                    teamCode:
                                        mission.ownTeam.code?.toLowerCase() ||
                                        '',
                                    missionId: mission.id || '',
                                }),
                                onClick: (
                                    ev?:
                                        | React.MouseEvent<HTMLElement>
                                        | React.KeyboardEvent<HTMLElement>,
                                    item?: IContextualMenuItem
                                ): boolean => {
                                    if (item && item.href) {
                                        updateContext({
                                            tenantCode: tenantCode,

                                            teamCode:
                                                mission.ownTeam?.code?.toLowerCase(),
                                            themePrimaryColourHex:
                                                mission.ownTeam?.division
                                                    ?.primaryColourHex,
                                            missionId: mission.id,
                                        });
                                        navigate(item.href);
                                    }

                                    // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                    if (ev) {
                                        ev.preventDefault();
                                        ev.defaultPrevented = false;
                                    }
                                    return true;
                                },
                            } as IContextualMenuItem);

                            subMenuItems.unshift({
                                key: `viewTeamDivider${currentTeamCode}`,
                                itemType: ContextualMenuItemType.Divider,
                            });

                            // Add the view team link
                            subMenuItems.unshift({
                                key: `viewTeam${teamCode}`,
                                text: 'View Team',
                                iconProps: {
                                    iconName: 'Group',
                                },
                                secondaryText: mission.ownTeam.name,
                                href: generatePath(paths.team, {
                                    tenantCode: tenantCode?.toLowerCase(),
                                    financialYearCode:
                                        financialYearCode?.toLowerCase(),
                                    teamCode:
                                        currentTeamCode?.toLowerCase() || '',
                                }),
                                onClick: (
                                    ev?:
                                        | React.MouseEvent<HTMLElement>
                                        | React.KeyboardEvent<HTMLElement>,
                                    item?: IContextualMenuItem
                                ): boolean => {
                                    if (item && item.href) {
                                        updateContext({
                                            tenantCode: tenantCode,
                                            teamCode:
                                                currentTeamCode?.toLowerCase(),
                                            themePrimaryColourHex:
                                                division?.primaryColourHex,
                                        });
                                        navigate(item.href);
                                    }
                                    // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                    if (ev) {
                                        ev.preventDefault();
                                        ev.defaultPrevented = false;
                                    }
                                    return true;
                                },
                            } as IContextualMenuItem);
                        }

                        const contributorMenuItems: IContextualMenuItem[] =
                            mission.ownTeam?.contributors
                                .filter((c) => c.userActive)
                                .map((c) =>
                                    getContributorMenuItem(
                                        c,
                                        currentTeamCode,
                                        division?.primaryColourHex
                                    )
                                ) || [];

                        subMenuItems.push(...contributorMenuItems);

                        return {
                            key: `mission${mission.id}`,
                            text: mission.owner,
                            secondaryText:
                                subMenuItems.length === 0
                                    ? mission.title
                                    : undefined,
                            iconProps: {
                                iconName: 'Contact',
                            },
                            href: navigation.getPathForParams({
                                tenantCode: tenantCode,
                                financialYearCode:
                                    financialYearCode?.toLowerCase(),
                                teamCode: currentTeamCode?.toLowerCase(),
                                missionId: mission.id || undefined,
                            }),
                            subMenuProps: subMenuItems.length
                                ? { items: subMenuItems }
                                : undefined,
                            split: subMenuItems.length ? true : false,
                            onClick: (
                                ev?:
                                    | React.MouseEvent<HTMLElement>
                                    | React.KeyboardEvent<HTMLElement>,
                                item?: IContextualMenuItem
                            ): boolean => {
                                if (item && item.href) {
                                    updateContext({
                                        tenantCode: tenantCode,
                                        themePrimaryColourHex:
                                            division?.primaryColourHex,
                                        teamCode: currentTeamCode,
                                        missionId: mission.id,
                                    });
                                    navigate(item.href);
                                }
                                // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                if (ev) {
                                    ev.preventDefault();
                                    ev.defaultPrevented = false;
                                }
                                return true;
                            },
                        } as IContextualMenuItem;
                    }
                );

                if (breadcrumb.leaderMission) {
                    menuItems.unshift({
                        key: 'leader' + breadcrumb.leaderMission.id,
                        text: breadcrumb.leaderMission.owner || '',
                        secondaryText: breadcrumb.leaderMission.title || '',
                        iconProps: {
                            iconName: 'PartyLeader',
                        },
                        href: navigation.getPathForParams({
                            tenantCode: tenantCode,
                            financialYearCode: financialYearCode?.toLowerCase(),
                            teamCode: breadcrumb.code || undefined,
                            missionId: breadcrumb.leaderMission.id || undefined,
                        }),
                        onClick: (
                            ev?:
                                | React.MouseEvent<HTMLElement>
                                | React.KeyboardEvent<HTMLElement>,
                            item?: IContextualMenuItem
                        ): boolean => {
                            if (item && item.href) {
                                updateContext({
                                    tenantCode: tenantCode,
                                    teamCode: breadcrumb.code,
                                    themePrimaryColourHex:
                                        breadcrumb.division?.primaryColourHex,
                                    missionId: breadcrumb.leaderMission?.id,
                                });
                                navigate(item.href);
                            }

                            // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                            if (ev) {
                                ev.preventDefault();
                                ev.defaultPrevented = false;
                            }
                            return true;
                        },
                    });

                    menuItems.push(
                        ...(breadcrumb.contributors
                            .filter((c) => c.userActive)
                            .map((c) =>
                                getContributorMenuItem(
                                    c,
                                    breadcrumb.code,
                                    breadcrumb.division?.primaryColourHex
                                )
                            ) || [])
                    );

                    menuItems.unshift({
                        key: `viewTeamDivider${breadcrumb.code}`,
                        itemType: ContextualMenuItemType.Divider,
                    });

                    // Add the view team link
                    menuItems.unshift({
                        key: 'viewTeam' + breadcrumb.code,
                        text: 'View Team',
                        iconProps: {
                            iconName: 'Group',
                        },
                        secondaryText: breadcrumb.name || '',
                        href: generatePath(paths.team, {
                            tenantCode: tenantCode?.toLowerCase(),
                            financialYearCode: financialYearCode?.toLowerCase(),
                            teamCode: breadcrumb.code?.toLowerCase() || '',
                        }),
                        onClick: (
                            ev?:
                                | React.MouseEvent<HTMLElement>
                                | React.KeyboardEvent<HTMLElement>,
                            item?: IContextualMenuItem
                        ): boolean => {
                            if (item && item.href) {
                                updateContext({
                                    tenantCode: tenantCode,
                                    teamCode: breadcrumb.code,
                                    themePrimaryColourHex:
                                        breadcrumb.division?.primaryColourHex,
                                });
                                navigate(item.href);
                            }
                            // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                            if (ev) {
                                ev.preventDefault();
                                ev.defaultPrevented = false;
                            }
                            return true;
                        },
                    } as IContextualMenuItem);
                }

                return {
                    key: `breadcrumb${breadcrumb.id}`,
                    text: breadcrumb.name || '',
                    menuProps: {
                        items: menuItems,
                    },
                } as AdvanceBreadcrumb;
            }
        );

        if (currentMissionItem) {
            items.push(currentMissionItem);
        } else if (currentContributorItem) {
            items.push(currentContributorItem);
        }

        if (tenantCode && financialYearCode) {
            const fyItem = {
                key: 'financialYear',
                text: financialYearCode.toUpperCase(),
                menuProps: {
                    items: sortedFys?.map((fy) => {
                        return {
                            key: `swapFy${fy.code?.toUpperCase()}`,
                            text: fy.code?.toLocaleUpperCase(),
                            secondaryText: `${dayjs(fy.startDate).format(
                                'MMM YYYY'
                            )} - ${dayjs(fy.endDate).format('MMM YYYY')}`,
                            href: navigation.getPathForParams({
                                tenantCode: tenantCode,
                                financialYearCode: fy.code?.toLowerCase(),
                            }),
                            onClick: (
                                ev?: React.MouseEvent | React.KeyboardEvent
                            ): boolean => {
                                navigateToFy(fy.code);
                                // // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                if (ev) {
                                    ev.preventDefault();
                                    ev.defaultPrevented = false;
                                }
                                return true;
                            },
                        } as IContextualMenuItem;
                    }),
                },
            };

            items.unshift(fyItem);
        }

        if (tenantCode) {
            items.unshift({
                key: 'root',
                text: tenantName || tenantCode,
                menuProps: {
                    items: [],
                },
            });
        }

        return items;
    }, [
        currentContributorItem,
        currentMissionItem,
        data?.breadcrumbs,
        financialYearCode,
        getContributorMenuItem,
        fyLoading,
        loading,
        navigate,
        navigateToFy,
        sortedFys,
        teamCode,
        tenantCode,
        tenantName,
        updateContext,
    ]);

    return {
        items: breadCrumbItems,
        loading: loading || fyLoading,
    };
}
