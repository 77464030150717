import 'core-js/es';
import 'unfetch/polyfill';
import './services/i18n';

import './font.scss';
import './index.css';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import App from './App';
import { authService } from './services/auth.service';
import { graphService } from './services/graph.service';
import { ApolloProvider } from '@apollo/client';
import { InitialState } from './services/context';
import { paths } from './services/navigation';

import { Home } from './scenes/Home';
import { NoTenantFound, AccountNotFound } from './scenes/Errors';
import { UserTenantQl, UserRoleQl } from './data/types';
import Loading, { UnthemedLoading } from './components/Loading';
import { PrivacyPolicy, TermsOfUse } from './scenes/Legal';
import {
    IConfiguration,
    configurationService,
} from './services/configuration.service';
import ContextProvider from './services/contextProvider';
import { HelpContent } from './components/navigation/HelpContent';
import TelemetryProvider from './components/TelemetryProvider';
import { HelpDownload } from './components/navigation/HelpDownload';

initializeIcons();
initializeFileTypeIcons();

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
    <div id="loader">
        <UnthemedLoading padding={0} />
    </div>
);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const basename: string = baseUrl || '/';

const populateInitialState = (
    configuration: IConfiguration,
    currentUserId: string | undefined,
    currentUserEmail: string | undefined,
    currentUserDisplayName: string | undefined,
    currentUserIsSelfHelpEnabled: boolean | undefined,
    currentUserIsNew: boolean | undefined,
    userTenants: UserTenantQl[],
    userRoles: UserRoleQl[]
): void => {
    InitialState.initialised = true;
    InitialState.currentUserId = currentUserId;
    InitialState.currentUserEmail = currentUserEmail;
    InitialState.currentUserDisplayName = currentUserDisplayName;
    InitialState.currentUserIsSelfHelpEnabled = currentUserIsSelfHelpEnabled;
    InitialState.currentUserIsNew = currentUserIsNew;
    InitialState.configuration = configuration;
    InitialState.userTenants = userTenants;
    InitialState.userRoles = userRoles;
};

const populateHelpState = async () => {
    const isLoggedIn = await authService.isLoggedInAsync();

    if (isLoggedIn) {
        const userConfig = await graphService.initialiseAsync();

        const user = authService.getUser();

        await populateInitialState(
            userConfig.configuration,
            userConfig.currentUserId,
            user?.emailAddress,
            userConfig.currentUserDisplayName || undefined,
            userConfig.currentUserIsSelfHelpEnabled,
            userConfig.currentUserIsNew,
            userConfig.userTenants,
            userConfig.userRoles
        );
    } else {
        const configuration =
            await configurationService.getConfigurationAsync();
        InitialState.configuration = configuration;
    }
};

if (window.location.pathname.startsWith(paths.help)) {
    populateHelpState().then(() => {
        root.render(
            <BrowserRouter basename={basename}>
                <Suspense fallback={<UnthemedLoading padding={0} />}>
                    <ContextProvider>
                        <TelemetryProvider>
                            <Routes>
                                <Route
                                    path={paths.help}
                                    element={<HelpContent />}
                                />
                                <Route
                                    path={paths.helpSection}
                                    element={<HelpContent />}
                                />
                                <Route
                                    path={paths.helpDownload}
                                    element={<HelpDownload />}
                                />
                            </Routes>
                        </TelemetryProvider>
                    </ContextProvider>
                </Suspense>
            </BrowserRouter>
        );
    });
} else
    authService.isLoggedInAsync().then((isLoggedIn) => {
        if (!isLoggedIn) {
            root.render(
                <BrowserRouter basename={basename}>
                    <Suspense fallback={<UnthemedLoading padding={0} />}>
                        <Routes>
                            <Route
                                path={paths.termsOfUse}
                                element={<TermsOfUse />}
                            />
                            <Route
                                path={paths.privacyPolicy}
                                element={<PrivacyPolicy />}
                            />
                            <Route path="*" element={<Home />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            );
        } else {
            try {
                graphService
                    .initialiseAsync()
                    .then(
                        async ({
                            configuration,
                            client,
                            currentUserId,
                            currentUserDisplayName,
                            currentUserIsSelfHelpEnabled,
                            currentUserIsNew,
                            userTenants,
                            userRoles,
                        }) => {
                            const user = authService.getUser();

                            populateInitialState(
                                configuration,
                                currentUserId,
                                user?.emailAddress,
                                currentUserDisplayName || undefined,
                                currentUserIsSelfHelpEnabled,
                                currentUserIsNew,
                                userTenants,
                                userRoles
                            );

                            if (!currentUserId) {
                                root.render(
                                    <AccountNotFound
                                        emailAddress={user?.emailAddress}
                                    />
                                );
                            } else if (
                                userTenants.length === 0 &&
                                !userRoles?.some(
                                    (ur) => ur.name === 'GlobalAdmin'
                                )
                            ) {
                                root.render(
                                    <NoTenantFound
                                        emailAddress={user?.emailAddress}
                                    />
                                );
                            } else if (
                                !userTenants.some((ut) => ut.tenant.isEnabled)
                            ) {
                                root.render(
                                    <NoTenantFound
                                        emailAddress={user?.emailAddress}
                                        isTenantDisabled
                                        disabledTenantName={
                                            userTenants.find(
                                                (ut) => !ut.tenant.isEnabled
                                            )?.tenant.description
                                        }
                                    />
                                );
                            } else {
                                root.render(
                                    <ApolloProvider client={client}>
                                        <BrowserRouter basename={basename}>
                                            <ContextProvider>
                                                <Suspense
                                                    fallback={
                                                        <Loading padding={0} />
                                                    }
                                                >
                                                    <App
                                                        configuration={
                                                            configuration
                                                        }
                                                    />
                                                </Suspense>
                                            </ContextProvider>
                                        </BrowserRouter>
                                    </ApolloProvider>
                                );
                            }
                        }
                    );
            } catch (error) {
                console.log(error);
            }
        }
    });

//serviceWorker.unregister();
