import React, { useCallback, useMemo } from 'react';
import { Rating, Text, Label } from '@fluentui/react';
import {
    GetMissionQuery,
    ReportPeriodTypes,
    useGetDependenciesLazyQuery,
    useGetMeasurePeriodDataLazyQuery,
    useGetMissionMentorLazyQuery,
    useGetMissionTasksLazyQuery,
    useGetTeamMissionsLazyQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useThemes } from './useThemes';
import { sorters } from '../data/sorters';
import dayjs from 'dayjs';

import { useMaiWebSockets } from './useMaiWebSockets';
import { useMaiFileUpload } from './useMaiFileUpload';
import { PromptBuilder, useMai } from './useMai';

const getRequiredEndPhrase = (
    mission: GetMissionQuery['mission']
): { hasRequiredPhrase: boolean; requiredEndPrase: string | undefined } => {
    let requiredEndPrase: string | undefined = undefined;

    const oneUpMission =
        mission?.team?.leaderMission &&
        mission?.team?.leaderMission.id !== mission.id
            ? mission?.team?.leaderMission
            : null;

    const oneUp =
        mission?.customMissionStatement1Up || oneUpMission?.missionStatement;

    if (oneUp) {
        const oneUpStatement = `in order to ${
            oneUp.indexOf('in order to') > 0
                ? oneUp
                      .substring(0, oneUp.indexOf('in order to'))
                      .replace(/,\s*$/, '') // any trailing comma
                      .replace(/^To\s*/, '') // remove To from the beginning
                      .trim()
                : oneUp
        }`;

        requiredEndPrase = oneUpStatement.trim().replace(/\.$/, '');
    }

    if (!oneUp && mission?.team?.division?.vision) {
        const oneUpStatement = `in order to ${mission?.team?.division?.vision
            .replace(/^To\s*/, '')
            .trim()}`;

        requiredEndPrase = oneUpStatement.trim().replace(/\.$/, '');
    }

    let hasRequiredPhrase = false;

    if (requiredEndPrase?.trim()) {
        if (mission?.missionStatement?.trim()) {
            if (
                mission.missionStatement
                    .trim()
                    .toUpperCase()
                    .replace(/\.$/, '')
                    .endsWith(requiredEndPrase?.toUpperCase())
            ) {
                hasRequiredPhrase = true;
            }
        }
    }

    return {
        hasRequiredPhrase,
        requiredEndPrase,
    };
};

export function useMissionMentor(): {
    isEnabled: boolean;
    isStreaming: boolean;
    contentMd: string;
    cancel: () => void;
    executePrompt: (promptName: string, prompt: string) => void;
    addTeamToPrompt: (
        promptBuilder: PromptBuilder,
        teamCode: string | null | undefined,
        fyCode: string | null | undefined,
        missionUserId: string | null | undefined
    ) => Promise<void>;
    reviewMissionStatement: (missionData: GetMissionQuery) => Promise<{
        newMissionStatement: string | null;
        content: JSX.Element;
    }>;
    missionAlignmentReport: (missionData: GetMissionQuery) => void;
    importFileAnalysis: (
        selectedMissionIds: string[],
        fileBlob: Blob | null
    ) => void;
    scanForMeasuresUsingAI: (fileBlob: Blob | null) => void;
} {
    const { isStreaming, contentMd, startStreaming, cancelStreaming } =
        useMaiWebSockets();

    const { getPromptBuilder } = useMai();

    const {
        isStreaming: isStreamingFromFileUpload,
        contentMd: contentMdFromFileUpload,
        startStreaming: startStreamingFromFileUpload,
        cancelStreaming: cancelStreamingFromFileUpload,
    } = useMaiFileUpload();

    const { currentRoles, currentUserEmail, currentTenantId, userTenants } =
        useStateContext();

    const { currentTheme } = useThemes();

    const [missionMentor] = useGetMissionMentorLazyQuery({
        fetchPolicy: 'network-only',
    });

    const cancel = useCallback(() => {
        cancelStreaming();
        cancelStreamingFromFileUpload();
    }, [cancelStreaming, cancelStreamingFromFileUpload]);

    const [getDependencies] = useGetDependenciesLazyQuery();

    const [getMissionTasks] = useGetMissionTasksLazyQuery();

    const [getTeamMissions] = useGetTeamMissionsLazyQuery();

    const [getPeriodData] = useGetMeasurePeriodDataLazyQuery();

    const addTeamToPrompt = useCallback(
        async (
            promptBuilder: PromptBuilder,
            teamCode: string | null | undefined,
            fyCode: string | null | undefined,
            missionUserId: string | null | undefined
        ) => {
            if (!teamCode || !fyCode || !currentTenantId) {
                return;
            }

            const teamMissionsQuery = await getTeamMissions({
                variables: {
                    teamCode: teamCode,
                    financialYearCode: fyCode,
                    tenantId: currentTenantId,
                },
            });

            const teamMissions = teamMissionsQuery.data?.teams;

            teamMissions?.forEach((t) => {
                const mission = teamMissions
                    .flatMap((tm) => tm.missions)
                    .find((m) => m.userId === missionUserId);

                if (t.leaderMission?.userId === missionUserId) {
                    promptBuilder.writeln(
                        `${t.leaderMission?.owner} is leader of the ${t.name} team. The other team members are:`
                    );
                } else if (mission?.owner) {
                    promptBuilder.writeln(
                        `${mission.owner} is a member of the ${t.name} team. The full team members are: `
                    );
                    promptBuilder.writeln(
                        `- Team Leader: ${t.leaderMission?.owner} (${t.leaderMission?.title})`
                    );
                }

                t.missions
                    .filter((tm) => tm.userId)
                    .filter((tm) => tm.userId !== t.leaderMission?.userId)
                    .sort(sorters.sequenceSorter)
                    .forEach((tm) => {
                        promptBuilder.writeln(`- ${tm.owner} (${tm.title})`);
                    });
            });

            promptBuilder.writeln();
        },
        [currentTenantId, getTeamMissions]
    );

    const executePrompt = useCallback(
        async (promptName: string, prompt: string) => {
            startStreaming(promptName, prompt);
            console.log(prompt);
        },
        [startStreaming]
    );

    const getTasksMd = useCallback(
        async (missionData: GetMissionQuery) => {
            const promptBuilder = new PromptBuilder();

            const tasks = missionData.tasksSummary;
            const mission = missionData.mission;

            if (!mission || !currentTenantId || !tasks?.length) {
                return '*NONE DEFINED*';
            }

            const fullTasksQuery = await getMissionTasks({
                variables: {
                    missionId: mission.id,
                    tenantId: currentTenantId,
                },
            });

            const fullTasks = fullTasksQuery.data?.tasks;

            const specifiedTasks = fullTasks
                ?.slice()
                .filter((t) => t.parentTaskId === null)
                .sort(sorters.sequenceSorter);

            specifiedTasks?.forEach(async (st, index) => {
                promptBuilder.writeln(
                    `## Specified Task ${index + 1}: ${st.name}`
                );
                promptBuilder.writeln();

                if (st.start) {
                    promptBuilder.writeln(
                        `Start: ${dayjs(st.start).format('DD MMM YYYY')}`
                    );
                }
                if (st.due) {
                    promptBuilder.writeln(
                        `Due: ${dayjs(st.due).format('DD MMM YYYY')}`
                    );
                }

                promptBuilder.writeln();

                promptBuilder.writeln(
                    `Category: ${st?.taskCategory?.name || 'NOT SET'}`
                );

                if (st?.tags.length) {
                    promptBuilder.writeln(
                        `Tags: ${st?.tags.map((t) => t.name).join(', ')}`
                    );
                }

                promptBuilder.writeln();

                const linkedMeasureNames: string[] = [];
                st?.linkedMeasures.forEach((lm) => {
                    const name = missionData.measureSummary.find(
                        (m) => m.id === lm.measureId
                    )?.name;

                    if (name) {
                        linkedMeasureNames.push(name);
                    }
                });

                promptBuilder.writeln('Linked Measures:');
                if (linkedMeasureNames.length) {
                    linkedMeasureNames.forEach((mn) =>
                        promptBuilder.writeln(`- ${mn}`)
                    );
                } else {
                    promptBuilder.writeln(`*NONE DEFINED*`);
                }

                promptBuilder.writeln();

                const impliedTasks =
                    fullTasks?.filter(
                        (it) => it.parentTaskId === st.id && !it.isDuplicate
                    ) || [];

                const headers = [
                    'Implied Task',
                    'Start Date',
                    'Due Date',
                    'Resources',
                ];

                const rows: string[][] = [];

                impliedTasks.forEach((it) => {
                    const fullTask = fullTasks?.find((ft) => ft.id === it.id);

                    const start = it.start
                        ? dayjs(it.start).format('DD MMM YYYY')
                        : 'NONE';

                    const due = it.due
                        ? dayjs(it.due).format('DD MMM YYYY')
                        : 'NONE';

                    const resources = fullTask?.resourcedTasks
                        .map((rt) => {
                            const isAccepted =
                                rt.utcAccepted ||
                                rt.resource?.userId === mission.userId ||
                                rt.resource?.userContributorFYs.some(
                                    (fy) =>
                                        fy.id ===
                                        mission.team?.division?.financialYear
                                            ?.id
                                ) ||
                                !rt.resource?.userMissionFYs.some(
                                    (fy) =>
                                        fy.id ===
                                        mission.team?.division?.financialYear
                                            ?.id
                                );

                            return `${
                                rt.resource?.displayName
                            } ${isAccepted ? '(ACCEPTED)' : rt.utcRejected ? '(REJECTED)' : '(NOT ACCEPTED)'}\n`;
                        })
                        .join(', ');

                    rows.push([
                        it.name || 'NO NAME',
                        start,
                        due,
                        resources || '',
                    ]);
                });

                if (rows.length) {
                    promptBuilder.writeln(
                        'The actions required to complete this specified task:'
                    );
                    promptBuilder.writeMarkdownTable(headers, rows);
                } else {
                    promptBuilder.writeln('*No implied tasks set*');
                }
                promptBuilder.writeln();
            });

            return promptBuilder.toString();
        },
        [currentTenantId, getMissionTasks]
    );

    const getDependenciesMd = useCallback(
        async (missionData: GetMissionQuery) => {
            const promptBuilder = new PromptBuilder();

            const mission = missionData.mission;

            if (
                mission?.team?.division?.financialYear?.code &&
                mission.userId &&
                currentTenantId
            ) {
                const dependencyData = await getDependencies({
                    variables: {
                        tenantId: currentTenantId,
                        financialYearCode:
                            mission?.team?.division?.financialYear?.code,
                        userId: mission.userId,
                    },
                });

                const resourcedTasks =
                    dependencyData?.data?.dependencies?.filter((d) =>
                        // Does the task have an outstanding dependency for this mission user?
                        (d?.resourcedTasks || []).some(
                            (rt) =>
                                (!rt?.utcAccepted || rt?.missionId === null) &&
                                !rt?.utcRejected &&
                                rt?.resource?.userId &&
                                rt?.resource?.userId.toUpperCase() ===
                                    mission.userId?.toUpperCase()
                        )
                    ) || [];

                if (resourcedTasks?.length) {
                    promptBuilder.writeln();
                    promptBuilder.writeln(
                        `The following tasks have not been accepted into this mission:`
                    );
                    resourcedTasks
                        .slice(0, 8)
                        .forEach((t) =>
                            promptBuilder.write(
                                ` - ${t.name} (Requested By ${t.mission?.username})\n`
                            )
                        );

                    promptBuilder.writeln();
                } else {
                    promptBuilder.writeln(`*No outstanding requests.*`);
                }
            }

            return promptBuilder.toString();
        },
        [currentTenantId, getDependencies]
    );

    const missionAlignmentReport = useCallback(
        async (missionData: GetMissionQuery) => {
            if (!missionData?.mission?.id) {
                throw 'Mission not loaded';
            }

            const mission = missionData.mission;

            const promptBuilder =
                await getPromptBuilder(`mission-alignment.md`);

            const { requiredEndPrase: requiredEndPhrase } =
                getRequiredEndPhrase(mission);

            const teamPromptBuilder = new PromptBuilder();

            await addTeamToPrompt(
                teamPromptBuilder,
                mission.team?.code,
                mission.team?.division?.financialYear?.code,
                mission.userId
            );

            for (const t of mission.leaderOfTeams) {
                await addTeamToPrompt(
                    teamPromptBuilder,
                    t?.code,
                    mission.team?.division?.financialYear?.code,
                    mission.userId
                );
            }

            let fyDetails = '';

            if (mission.team?.division?.financialYear) {
                const { startDate, endDate } =
                    mission.team.division.financialYear;

                if (startDate && endDate) {
                    const isCurrent = dayjs().isBetween(startDate, startDate);
                    const isFuture = dayjs(startDate).isAfter(dayjs());

                    fyDetails = `The mission is for the ${
                        isCurrent ? 'current ' : isFuture ? 'next ' : ''
                    }financial year starting ${dayjs(startDate).format(
                        'DD MMM YYYY'
                    )} and ending ${dayjs(endDate).format('DD MMM YYYY')}`;

                    if (isFuture) {
                        fyDetails = '\nThe mission is in the planning phase.';
                    }
                }
            }

            const dependenciesMd = await getDependenciesMd(missionData);

            const measurePromptBuilder = new PromptBuilder();

            if (missionData.measureSummary.length) {
                measurePromptBuilder.writeln(
                    `Count of measures of success: ${missionData.measureSummary.length}`
                );

                const rows: string[][] = [];

                const measureGroups = [
                    ...(missionData.measureGroups
                        ?.slice()
                        .sort(sorters.sequenceSorter) || []),
                    { id: null, name: 'Ungrouped' },
                ];

                for (const mg of measureGroups) {
                    const measures =
                        missionData.measureSummary
                            ?.slice()
                            ?.filter((m) => m.measureGroupId === mg.id)
                            ?.sort(sorters.sequenceSorter) || [];

                    for (const m of measures) {
                        const periodData = await getPeriodData({
                            variables: {
                                forDateTime: null,
                                measureId:
                                    m?.isLinked && m.linkedFromMeasureId
                                        ? m.linkedFromMeasureId
                                        : m?.id || '',
                                tenantId: currentTenantId || '',
                                reportPeriod: null,
                                reportPeriodType: ReportPeriodTypes.None,
                            },
                        });

                        const measureData =
                            periodData.data?.periodData?.measurePeriodData;

                        rows.push([
                            mg.name || '',
                            m.name || '',
                            measureData?.targetFormatted
                                ? `${measureData?.targetFormatted} (${measureData?.period})`
                                : 'NONE SET',
                        ]);
                    }
                }

                const headers = ['Group', 'Name', 'Current Target'];

                if (rows.every((r) => r[0] === 'Ungrouped')) {
                    headers.shift();
                    rows.forEach((r) => r.shift());
                }

                measurePromptBuilder.writeMarkdownTable(headers, rows);
            } else {
                measurePromptBuilder.writeln('*NONE PROVIDED*');
            }

            const specifiedTasksMd = await getTasksMd(missionData);

            const prompt = promptBuilder
                .toString()
                .replace('[[FY INFO]]', fyDetails || '*NOT PROVIDED*')
                .replace('[[MISSION OWNER]]', mission.owner || '*NOT PROVIDED*')
                .replace('[[MISSION TITLE]]', mission.title || '*NOT PROVIDED*')
                .replace(
                    '[[MEASURES OF SUCCESS]]',
                    measurePromptBuilder.toString()
                )
                .replace('[[SPECIFIED TASKS]]', specifiedTasksMd)
                .replace(
                    '[[MISSION STATEMENT]]',
                    mission.missionStatement || '*NOT PROVIDED*'
                )
                .replace(
                    '[[REQUIRED END PHRASE]]',
                    requiredEndPhrase || '*NOT PROVIDED*'
                )
                .replace('[[OUTSTANDING REQUESTS]]', dependenciesMd)
                .replace('[[TEAM MEMBERS LIST]]', teamPromptBuilder.toString());

            executePrompt('missionAlignment', prompt);
        },
        [
            addTeamToPrompt,
            currentTenantId,
            executePrompt,
            getDependenciesMd,
            getPeriodData,
            getPromptBuilder,
            getTasksMd,
        ]
    );

    const reviewMissionStatement = useCallback(
        async (missionData: GetMissionQuery) => {
            const mission = missionData.mission;

            const missionStatement = mission?.missionStatement?.trim();
            const mightBeMultipleObjective = missionStatement
                ? missionStatement
                      .substring(0, missionStatement.indexOf('in order to'))
                      .indexOf(' and ') > -1
                : false;

            const tasksNames = missionData.tasksSummary
                .slice()
                .filter((t) => t.parentTaskId === null && !t.isDuplicate)
                .slice(0, 8)
                .sort(sorters.sequenceSorter)
                .map((t) => t.name);

            let resourcedTaskNames: string[] = [];

            // Load the resourced tasks, if they don't have a task
            if (
                !tasksNames.length &&
                currentTenantId &&
                mission?.team?.division?.financialYear?.code &&
                mission.userId
            ) {
                const dependencyData = await getDependencies({
                    variables: {
                        tenantId: currentTenantId,
                        financialYearCode:
                            mission?.team?.division?.financialYear?.code,
                        userId: mission.userId,
                    },
                });

                resourcedTaskNames =
                    dependencyData?.data?.dependencies
                        ?.filter((d) =>
                            // Does the task have an outstanding dependency for this mission user?
                            (d?.resourcedTasks || []).some(
                                (rt) =>
                                    (!rt?.utcAccepted ||
                                        rt?.missionId === null) &&
                                    !rt?.utcRejected &&
                                    rt?.resource?.userId &&
                                    rt?.resource?.userId.toUpperCase() ===
                                        mission.userId?.toUpperCase()
                            )
                        )
                        .filter((t) => !t.utcPostponed && !t.utcCancelled)
                        .map((t) => t.name || '') || [];
            }

            const oneUpMission =
                mission?.team?.leaderMission &&
                mission?.team?.leaderMission.id !== mission.id
                    ? mission?.team?.leaderMission
                    : null;

            const oneUp =
                mission?.customMissionStatement1Up ||
                oneUpMission?.missionStatement;

            const { hasRequiredPhrase, requiredEndPrase } =
                getRequiredEndPhrase(mission);

            const promptBuilder = new PromptBuilder();

            promptBuilder.writeln(
                missionStatement
                    ? 'Follow the steps below to first evaluate and rate this mission statement and then suggest a new mission statement if needed.'
                    : 'Suggest a mission statement for this mission.'
            );

            promptBuilder.writeln();

            if (missionStatement) {
                promptBuilder.writeln(
                    `### Mission Statement\n> ${missionStatement}`
                );
            }

            if (mission?.title) {
                promptBuilder.writeln(`### Mission Title\n> ${mission.title}`);
            }

            if (oneUpMission?.title) {
                promptBuilder.writeln(
                    `### Reporting Manager's Title\n> ${oneUpMission?.title}`
                );
            }

            if (oneUp) {
                promptBuilder.writeln(
                    `### Reporting Manager's Mission Statement\n> ${oneUp}`
                );
            }

            if (!oneUp && mission?.team?.division?.vision) {
                promptBuilder.writeln(
                    `### Company Vision\n> ${mission?.team?.division?.vision}`
                );
            }

            if (tasksNames.length) {
                promptBuilder.writeln(`### Main Effort\n${tasksNames[0]}`);
            } else if (resourcedTaskNames.length) {
                promptBuilder.writeln(
                    `### Requested Tasks\nThe mission has been requested as a resource on the following tasks:\n${resourcedTaskNames
                        .map((t) => `- ${t}`)
                        .join('\n')}`
                );
            }

            promptBuilder.writeln();

            if (missionStatement) {
                promptBuilder.writeln(`# Step 1: Evaluation`);

                promptBuilder.writeln(
                    `Evaluate and rate the existing mission statement out of 5 based on this guidance. If the guidance has been met, score a rating of 5.`
                );
                promptBuilder.writeln(
                    `- Focused on what needs to be done, not how is done`
                );
                promptBuilder.writeln(`- An outcome statement`);
                promptBuilder.writeln(`- Single-minded focus and succinct`);
                promptBuilder.writeln(`- Single objective`);

                if (requiredEndPrase) {
                    if (hasRequiredPhrase) {
                        promptBuilder.writeln(
                            `- The mission statement correctly ends with the required phrase: "${requiredEndPrase}"`
                        );
                    } else {
                        promptBuilder.writeln(
                            `- The mission statement does not end with the required phrase: "${requiredEndPrase}"`
                        );
                    }
                }

                promptBuilder.writeln(
                    `${
                        mightBeMultipleObjective
                            ? '- Avoids including multiple objectives before the words in order to'
                            : 'NOTE: this mission statement does not include the word "and" which indicates a single objective'
                    }`
                );

                promptBuilder.writeln();

                promptBuilder.writeln(`# Step 2: Suggestion`);
                promptBuilder.writeln(
                    `After evaluation, if the rating is less than 5, suggest an improved mission statement based on the evaluation guidance above, it must also:`
                );
                promptBuilder.writeln(
                    `- Avoid conjunctions such as "and" and lists before the words "in order to" which often indicate multiple objectives.`
                );
                promptBuilder.writeln(
                    `- End EXACTLY with the phrase: "${requiredEndPrase}".`
                );
            } else {
                promptBuilder.writeln(`# Suggested Mission Statement`);
                promptBuilder.writeln(
                    `- Focused on what needs to be done, not how is done, an outcome statement                `
                );
                promptBuilder.writeln(
                    `- Single-minded focus and succinct                `
                );
                promptBuilder.writeln(`- Single objective`);
                promptBuilder.writeln(
                    `- Avoids conjunctions such as "and" and lists before the words "in order to" which often indicate multiple objectives.`
                );
                if (requiredEndPrase) {
                    promptBuilder.writeln(
                        `- Ends **exactly** with the required phrase: "${requiredEndPrase}".`
                    );
                }
            }

            promptBuilder.writeln();

            promptBuilder.writeln(
                `Keep the response brief without repeating unnecessary information. Respond in JSON format. Here's an example of your output format:`
            );

            if (missionStatement) {
                promptBuilder.writeln(`{
                "evaluation": "",
                "suggested_mission_statement_explanation": "",
                "suggested_mission_statement": "",
                "rating": 5
}`);
            } else {
                promptBuilder.writeln(`{
                "suggested_mission_statement_explanation": "",
                "suggested_mission_statement": ""
}`);
            }

            const result = await missionMentor({
                variables: {
                    promptName: 'Mission Statement',
                    prompt: promptBuilder.toString(),
                },
            });

            let evaluation: string | null = null;
            let explanation: string | null = null;
            let suggestedMissionStatement: string | null = null;
            let rating: number | null | undefined = null;

            let contentJson = result.data?.missionMentor;

            if (contentJson && contentJson.indexOf('```json') > -1) {
                contentJson = contentJson.replaceAll('```json', '');
                contentJson = contentJson.replaceAll('```', '');
            }

            if (contentJson) {
                const json = JSON.parse(contentJson);

                evaluation = json.evaluation;
                explanation = json.suggested_mission_statement_explanation;
                suggestedMissionStatement = json.suggested_mission_statement;
                rating = json.rating;
            }

            return {
                newMissionStatement: suggestedMissionStatement,
                content: (
                    <div>
                        {mission?.missionStatement?.trim() && (
                            <div>
                                <Label>Mission Statement:</Label>
                                <Text
                                    block
                                    style={{
                                        marginBottom: 8,
                                    }}
                                >
                                    {mission.missionStatement}
                                </Text>
                            </div>
                        )}

                        <div>
                            {rating !== null && rating !== undefined && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 8,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Label>Rating:</Label>
                                    <Rating
                                        rating={rating}
                                        max={5}
                                        allowZeroStars
                                        readOnly
                                    />
                                </div>
                            )}
                        </div>

                        {!!evaluation && (
                            <Text
                                block
                                style={{
                                    whiteSpace: 'pre-line',
                                    marginBottom: 8,
                                }}
                            >
                                {evaluation}
                            </Text>
                        )}

                        {!!explanation && (
                            <Text
                                block
                                style={{
                                    whiteSpace: 'pre-line',
                                    marginBottom: 8,
                                }}
                            >
                                {explanation}
                            </Text>
                        )}

                        {!!suggestedMissionStatement && (
                            <p
                                style={{
                                    padding: 8,
                                    backgroundColor:
                                        currentTheme.palette.themeLighterAlt,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor:
                                        currentTheme.palette.themeLighter,
                                }}
                            >
                                <Text
                                    block
                                    variant="xSmall"
                                    style={{
                                        marginBottom: 8,
                                    }}
                                >
                                    Suggested Mission Statement
                                </Text>

                                <Text block>{suggestedMissionStatement}</Text>
                            </p>
                        )}
                    </div>
                ),
            };
        },
        [currentTenantId, currentTheme.palette, getDependencies, missionMentor]
    );

    const importFileAnalysis = useCallback(
        async (selectedMissionIds: string[], fileBlob: Blob | null) => {
            const promptBuilder = new PromptBuilder();

            promptBuilder.writeln(
                'I will give you a table. Can you tell me how many rows and how many columns are in this table? Here is the table:'
            );
            promptBuilder.writeln();
            // const headers = ['Tablename', 'Col1', 'Col2'];
            // const rows = [
            //   ['Row1', '10', '10'],
            //   ['Row2', '20', '20'],
            //   ['Row3', '30', '30'],
            // ];
            // promptBuilder.writeMarkdownTable(headers, rows);

            // Using the Table method
            // promptBuilder.writeln('TableName   Col1  Col2');
            // promptBuilder.writeln('Row1   10  10');
            // promptBuilder.writeln('Row2   20  20');
            // promptBuilder.writeln('Row3   30  30');

            // const measureData = await getAllMeasures({
            //     variables: {
            //         tenantId: currentTenantId || '',
            //         teamId: null,
            //         financialYearCode: currentFinancialYearCode || null,
            //         taskCategoryIds: null,
            //         financialYearActiveDate: null,
            //         forDateTime: null,
            //     },
            // });

            // const missionId =
            //   selectedMissionIds.length > 0 ? selectedMissionIds[0] : '';

            // const { data: measureData, loading } = useGetMissionMeasuresQuery({
            //   variables: {
            //     tenantId: currentTenantId || '',
            //     missionId: missionId || '',
            //   },
            // });

            // const { loading, data, error, refetch } = useGetMissionQuery({
            //   skip: !missionId || !currentTenantId,
            //   variables: {
            //     tenantId: currentTenantId || '',
            //     missionId: missionId || '',
            //   },
            // });

            // const missionData: GetMissionQuery = null;
            // const mission = missionData.mission;
            // const fyStartDate =
            //   mission?.team?.division?.financialYear?.startDate;
            // const measures =
            //   missionData.measureSummary
            //     ?.slice()
            //     ?.sort(sorters.sequenceSorter) || [];
            //
            // const fyStartDate: string | undefined = undefined;

            // var measures = measureData.data?.measures;
            // console.log('selectedMissionIds', selectedMissionIds);

            // if (selectedMissionIds.length > 0 && measures) {
            //     console.log('yeah I am going in here afterall');
            //     measures = measures.filter(
            //         (m) =>
            //             !selectedMissionIds ||
            //             selectedMissionIds.some((k) => k == m.mission?.id)
            //     );
            // }
            // console.log('filtered measures', measures);

            // buildMeasureIdTable(promptBuilder, measures || []);

            promptBuilder.writeln();
            promptBuilder.writeln('Please can you also print the table.');
            // promptBuilder.writeln( 'Please do not print the table in your answer.');

            startStreamingFromFileUpload(
                'importFileAnalysis',
                promptBuilder.toString(),
                'mai-import',
                fileBlob
            );
        },
        [startStreamingFromFileUpload]
    );

    const scanForMeasuresUsingAI = (fileBlob: Blob | null) => {
        startStreamingFromFileUpload(
            'new-measure-with-ai-scan',
            '',
            'mai-backend',
            fileBlob
        );
    };

    const userTenant = userTenants.find(
        (ut) => ut.tenantId === currentTenantId
    );
    const isMaiEnabled = useMemo(() => {
        return (
            userTenant?.tenant.isMaiEnabled ||
            currentRoles?.some((r) => r === 'Developer') ||
            currentUserEmail?.toLocaleLowerCase() ===
                'jweaver1@gpstrategies.com' ||
            currentUserEmail?.toLocaleLowerCase() === 'vswart@gpstrategies.com'
        );
    }, [currentRoles, currentUserEmail, userTenant?.tenant.isMaiEnabled]);

    return {
        contentMd: contentMd || contentMdFromFileUpload,
        isStreaming: isStreaming || isStreamingFromFileUpload,
        cancel: cancel,
        isEnabled: isMaiEnabled,
        executePrompt: executePrompt,
        addTeamToPrompt,
        missionAlignmentReport,
        reviewMissionStatement,
        importFileAnalysis,
        scanForMeasuresUsingAI,
    };
}
